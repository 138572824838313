<template>
  <div class="knowledge-base relative landing-page-wrapper new-langing-ui">
    <landing-page-header
    />
    <!-- search input -->
    <section
      id="knowledge-base-search"
      class="content-info unset-padding-responsive no-white-space"
    >
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{ backgroundImage: `url(${require('@/assets/images/banner/banner.png')})` }"
      >
        <b-card-body class="card-body">
          <!-- form -->
          <b-form class="kb-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="knowledgeBaseSearchQuery"
                placeholder="Ask a question...."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ search input -->

    <section id="knowledge-base-content">
      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in filteredKB"
          :key="item.id"
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-link
            class="text-black"
            :to="{ path: getViewUrl(item) }"
          >
            <b-card
              class="text-center cursor-pointer object-fit-knowledge"
              :img-src="$helpers.imageHelper(item.picture)"
              :img-alt="item.title"
              img-top
            >
              <h4>{{ item.title }}</h4>
              <b-card-text class="mt-1">
                {{ item.description }}
              </b-card-text>
            </b-card>
          </b-link>
        </b-col>
        <b-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            {{ $t('Search result not found') }}!!
          </h4>
        </b-col>
      </b-row>
    </section>
    <landing-page-footer />
    <AppFooterBlack />
  </div>
</template>

<script>
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import { showDangerNotification, showErrorNotification, showLoader, hideLoader } from '@/@core/comp-functions/ui/app'
import { useUserUi } from './users/useUser'

export default {
  components: {
    LandingPageHeader,
    LandingPageFooter,
    AppFooterBlack,
  },
  data() {
    return {
      knowledgeBaseSearchQuery: '',
      kb: [],
    }
  },
  computed: {
    filteredKB() {
      const knowledgeBaseSearchQueryLower = this.knowledgeBaseSearchQuery.toLowerCase()
      return this.kb.filter(
        item => (item.title && item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower)) || (item.description && item.description.toLowerCase().includes(knowledgeBaseSearchQueryLower)),
      )
    },
  },
  created() {
    const { knowledgeBaseCategory } = useUserUi()
    showLoader()
    knowledgeBaseCategory()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.kb = data.responseData
        } else {
          showDangerNotification(this, data.message)
        }
        hideLoader()
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    getViewUrl(blog) {
      return `/knowledge-base/category/${blog.title.trim().toLowerCase().replace(/ /g, '-').replace(/[^a-zA-Z- ]/g, '')}/${blog.id}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>

<style scoped>
.landing-page-wrapper .content-info {
  padding-top: 6rem;
}

#knowledge-base-content {
  padding-right: 80px;
  padding-left: 80px;
}

@media only screen and (max-width: 767px) {
  #knowledge-base-content {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing-page.scss';
</style>
