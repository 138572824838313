import { render, staticRenderFns } from "./KnowledgeBase.vue?vue&type=template&id=34e480ee&scoped=true"
import script from "./KnowledgeBase.vue?vue&type=script&lang=js"
export * from "./KnowledgeBase.vue?vue&type=script&lang=js"
import style0 from "./KnowledgeBase.vue?vue&type=style&index=0&id=34e480ee&prod&lang=scss"
import style1 from "./KnowledgeBase.vue?vue&type=style&index=1&id=34e480ee&prod&scoped=true&lang=css"
import style2 from "./KnowledgeBase.vue?vue&type=style&index=2&id=34e480ee&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e480ee",
  null
  
)

export default component.exports